<template>
  <div class="scroll-view" @scroll="viewScroll">
    <div ref="scroll-box">
      <slot></slot>
      <div class="bottom-text" ref="bottom-text">
        {{ bottomText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "scrollView",
  props: { 
    pageInfo: {
      type: Object,
      default: {
        hasNextPage: true,
        pageSize: 15,
        pageNum: 1,
      },
    },
  },
  data() {
    return {
      bottomText: "没有更多数据了",
      loadLock: false,
    };
  },
  created() {
 
  },
  methods: {
    viewScroll(event) {
      let triggerValue =
        this.$refs["scroll-box"].offsetHeight -
        (event.target.clientHeight + event.target.scrollTop);
      if (triggerValue < 10 && !this.loadLock) {
        if (this.pageInfo.hasNextPage) {
          this.loadLock = true;
          this.pageInfo.pageNum = this.pageInfo.pageNum + 1;
          this.$emit("loadNewData", {
            pageSize: this.pageInfo.pageSize,
            pageNum: this.pageInfo.pageNum,
          });

          this.bottomText = "加载中...";
          setTimeout(() => {
            this.loadLock = false;
          }, 100);
        } else {
          this.bottomText = "没有更多数据了";
          this.loadLock = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.scroll-view {
  height: 100%;
  overflow-y: auto;
}
.bottom-text {
  text-align: center;
  padding: 30px 40px;
  font-size: 16px;
  color: #999;
}
</style>